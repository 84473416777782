import { launchUriHandler } from './protocol_starter';
import { onConvertProgress, onConvertSuccess, onUploadProgress } from './callbacks';
import { t } from './localization';
import EventEmitter from '../common/event_emitter';
import { alertDialog, confirmDialog } from '../common/dialog';
import UAParser from 'ua-parser-js';

const logger = Logger.get('Recording App');
const ROOT_URL = `${window.location.protocol}//${window.location.hostname}`;

const defaultOptions = {
  newSessionUrl: `${ROOT_URL}/${_app.info.site}/manage/create_recording_application_session`,
  privacyDisclaimerUrl: `${ROOT_URL}/${_app.info.site}/manage/disclaimer`,
  configUrl: `${ROOT_URL}/api/recording/config`,
  setupButton: true
};

export class Application extends EventEmitter {
  constructor (options = {}) {
    super();

    this.userAgent = UAParser(navigator.userAgent);
    this.options = Object.assign(defaultOptions, options);
    this.session = null;
    this.loadedApp = false;
    this.state = 'unloaded';
    this.messages = {};
    this.origin = null;
    this.handlerLaunching = false;
    this.connected = false;
    this.context = null;
    this.visitId = null; // ID of the visit object for the current session.

    if (this.options.launchButton) {
      if (typeof this.options.launchButton === 'string') {
        this.options.launchButton = document.getElementById(this.options.launchButton);
      }

      this.setLaunchButton(this.options.launchButton);
    }
  }

  set state (state) {
    this._state = state;

    if (state === 'unloaded' && this.launchButton) {
      this.launchButton.classList.remove('disabled');
      this.launchButton.style.display = 'block';
    }

    return this._state;
  }

  get state () {
    return this._state;
  }

  set appOptions (value) {
    this._appOptions = value;
  }

  get appOptions () {
    if (this.options.origin === 'live') {
      return Object.assign(this._appOptions, { stream_url: this.options.stream_url });
    } else {
      return this._appOptions;
    }
  }

  get isMacOS() {
    try {
      return this.userAgent.os.name === 'Mac OS';
    } catch(e) {
      return false;
    }
  }

  get preloaderURL () {
    if (this.isMacOS) {
      return '/recording_application/AppLauncher.dmg';
    }

    return '/recording_application/AppLauncher.exe';
  }

  setLaunchButton (launchButton) {
    this.launchButton = launchButton;
    this.setOptions(JSON.parse(this.launchButton.getAttribute('data-options')));

    if (this.options.setupButton) {
      this.launchButton.addEventListener('click', e => {
        e.preventDefault();

        this.launchButton.classList.add('disabled');
        this.launch();
      });
    }
  }

  setOptions (options) {
    this.options = Object.assign(this.options, options);
  }

  createHelpPopup (type) {
    this.removeHelpPopup();
    this.helpPopup = document.createElement('div');
    this.helpPopup.className = 'preloader-launch-popup';
    document.body.appendChild(this.helpPopup);

    const helpText = document.createElement('p');
    const problemText = document.createElement('span');
    const downloadLink = document.createElement('a');
    const closeIcon = document.createElement('i');

    downloadLink.href = this.preloaderURL;

    if (type === 'preloading') {
      helpText.innerText = t('guide_start');
      problemText.innerText = t('nothing_happens');
      downloadLink.innerText = t('download_again');
    } else {
      if (this.isMacOS) {
        helpText.innerText = t('guide_first_start_mac');
      } else {
        helpText.innerText = t('guide_first_start');
      }

      problemText.innerText = t('download_does_not_start');
      downloadLink.innerText = t('download_manually');
    }

    closeIcon.className = 'material-icons';
    closeIcon.innerText = 'close';
    closeIcon.addEventListener('click', () => this.removeHelpPopup());

    this.helpPopup.appendChild(helpText);
    this.helpPopup.appendChild(problemText);
    this.helpPopup.appendChild(downloadLink);
    this.helpPopup.appendChild(closeIcon);
  }

  removeHelpPopup () {
    if (this.helpPopup) {
      document.body.removeChild(this.helpPopup);
      this.helpPopup = null;
    }
  }

  showHelpPopup (type) {
    if (this.launchButton) {
      this.launchButton.parentNode.setAttribute('data-help', type);
    } else {
      this.createHelpPopup(type);
    }

    if (type === 'downloading') {
      // Enable launch button, since the user needs to press it
      // again after download/install
      this.launchButton.classList.remove('disabled');
      this.state = 'unloaded';
    }
  }

  hideHelpPopup () {
    if (this.launchButton) {
      this.launchButton.parentNode.removeAttribute('data-help');
    } else {
      this.removeHelpPopup();
    }
  }

  setOptions (options) {
    this.options = Object.assign(this.options, options);
  }

  isReady () {
    return this.loadedApp;
  }

  startAndCall (callback) {
    this.onready = () => {
      if (typeof callback === 'function') {
        callback();
      } else if (typeof this[callback] === 'function') {
        this[callback]();
      }

      this.onready = null;
    };

    if (this.isReady()) {
      this.onready();
    } else {
      this.launch();
    }
  }

  launch (callback = null) {
    if (this.state !== 'unloaded') {
      return;
    }

    this.handlerLaunching = true;

    this.ensurePrivacyDisclaimer().then(() => {
      this.ensureSession().then((session) => {
        let uri;
        let configUrl = `${this.options.configUrl}?token=${session}&visit_id=${this.visitId}`;

        if (this.options.origin === 'live') {
          configUrl += `&origin=live&live_endpoint=${this.options.live_endpoint}&live_key=${this.options.live_key}&live_auth=${this.options.live_auth}`;
        }

        uri = `coovi-ra://launch/?url=${encodeURIComponent(configUrl)}`;

        this.setupRemoteWebsocket();
        
        launchUriHandler(uri).then(() => {
          this.handlerLaunching = false;
          this.showHelpPopup('preloading');
          this.trigger('handler-registered');
        }, () => {
          // Handler is not registered yet, trigger download
          this.trigger('download-started');

          if (this.userAgent.browser.name === 'Chrome' || this.userAgent.browser.name === 'Edge') {
            // Currently we can not detect if the protocol handler is already
            // registered from Chrome or Edge. Thus we always assume it is registered.
            this.showHelpPopup('preloading');
            return;
          } else {
            let iframe = document.getElementById('launcher_iframe');

            if (!iframe) {
              iframe = document.createElement('iframe');
              iframe.style.display = 'none';
              document.body.appendChild(iframe);
            } else {
              iframe.src = null;
            }

            iframe.src = this.preloaderURL;
          }

          this.showHelpPopup('downloading');
        });

        if (this.isMacOS) {
          this.state = 'loading';
        } else {
          this.state = 'preloading';
          this.on('loaded', this.onLoaded.bind(this))
        }

        if (typeof callback === 'function') {
          callback();
        }

      }).catch((e) => {
        logger.error(e);
        this.handlerLaunching = false;
        this.state = 'unloaded';
      });
    }, () => {
      // Privacy disclaimer is needed to be accepted, but was not accepted
      this.handlerLaunching = false;
      this.state = 'unloaded';
    });
  }

  onLoaded() {
    this.connected = true;
    this.off('loaded', this.onLoaded);
  }

  ensurePrivacyDisclaimer() {
    return new Promise((resolve, reject) => {
      if (!_app.info.recordingApp.showPrivacyDisclaimer) {
        resolve();
        return;
      }

      confirmDialog({
        title: t('privacy_disclaimer'),
        content:
          `<p class="privacy-disclaimer-text">${t('privacy_disclaimer_text')}</p>` +
          `<div class="privacy-disclaimer-remember">` +
          `  <input data-role="checkbox" id="privacy_disclaimer_remember" type="checkbox" class="k-checkbox"/>` +
          `  <label class="k-checkbox-label" for="privacy_disclaimer_remember">${t('do_not_show_again')}</label>` +
          `</div>`,
        actions: [{
          text: t('understood'),
          action: () => {
            // Accepted privacy disclaimer
            const shouldRemember = document.getElementById('privacy_disclaimer_remember').checked;

            if (shouldRemember) {
              $.post(this.options.privacyDisclaimerUrl, { remember: true });
            }

            _app.info.recordingApp.showPrivacyDisclaimer = false;
            resolve();
          },
          primary: true
        }, {
          text: t('cancel'),
          action: reject
        }]
      });
    });
  }

  setupRemoteWebsocket () {
    this.remoteWebsocket = VERSTEHE.cable.subscriptions.create({
      channel: 'RecordingApplicationChannel',
      session_id: this.session,
      role: 'web'
    }, {
      received: payload => {
        if (payload.event === 'ra_response') {
          // Response from previous `sendMessage` function call
          // in recording app
          this.responseReceived(
            payload.data.message_id,
            payload.data.response
          );
        } else if (payload.event === 'ra_exit') {
          // RA Websocket connection closed
          this.disconnect();
        } else {
          // Events triggered by RA over HTTP Requests to /api/recording/events
          this.eventReceived(payload.event, payload.message);
        }
      },
      sendMessage: (message) => {
        this.remoteWebsocket.perform('send_message', {
          session_id: this.session,
          message
        });
      }
    });
  }

  disconnect () {
    this.state = 'unloaded';
    this.session = null;
    this.loadedApp = false;
    VERSTEHE.cable.subscriptions.remove(this.remoteWebsocket);
    this.trigger('closed');
  }

  responseReceived (messageId, response) {
    if (this.messages[messageId] && typeof this.messages[messageId].callback === 'function') {
      this.messages[messageId].callback(response);
    }

    delete this.messages[messageId];
  }

  eventReceived (event, message) {
    logger.debug(`Event "${event}":`, message);

    // Transform to CamelCase
    let eventFunction = event.replace(/(\_[a-z])/g, (match) => {
      return match.toUpperCase().replace('_', '');
    });

    if (typeof this[eventFunction] === 'function') {
      this[eventFunction](message);
    } else {
      logger.error('Can not call function, because it does not exist', eventFunction);
    }
  }

  call (method, params = {}) {
    return new Promise((resolve, reject) => {
      let uid = generateUID();
      let message = {
        message_id: uid,
        action: method,
        message: params
      };

      this.messages[uid] = {
        data: message,
        callback: resolve
      };

      this.remoteWebsocket.sendMessage(message);
      logger.debug((new Date()).getTime(), 'sendMessage', message);
    });
  }

  ensureSession () {
    return new Promise((resolve, reject) => {
      if (this.session) {
        resolve(this.session);
      } else {
        $.ajax({
          url: this.options.newSessionUrl,
          method: 'POST',
          data: {
            origin: this.options.origin
          },
          success: data => {
            this.session = data.authorization_token;
            this.appOptions = data;
            this.visitId = data.visit_id
            resolve(this.session);
          },
          error: xhr => {
            reject(xhr);
          }
        });
      }
    });
  }

  createAmor () {
    let url = `${ROOT_URL}/api/recording/amors`;
    return this.apiCall(url, 'POST', { film_id: this.options.filmId });
  }

  getAmor (id) {
    let url = `${ROOT_URL}/api/recording/amors/${id}`;
    return this.apiCall(url, 'GET');
  }

  deleteAmor (id) {
    let url = `${ROOT_URL}/api/recording/amors/${id}`;
    return this.apiCall(url, 'DELETE');
  }

  unlockAmor (id) {
    let url = `${ROOT_URL}/api/recording/amors/unlock/${id}`;
    return this.apiCall(url, 'PUT');
  }

  apiCall (url, method, data = {}) {
    return new Promise((resolve, reject) => {
      this.ensureSession().then((session) => {
        $.ajax({
          url: url,
          method: method,
          data: data,
          success: resolve,
          headers: {
            Authorization: `Token ${session}`
          },
          error: (xhr) => {
            logger.error('Request failed', method, url, data, xhr);
            reject(xhr);
          }
        });
      });
    });
  }

  record () {
    if (!this.isReady() || this.state === 'uploading') {
      logger.debug('Can not start recording currently.');
      return;
    }

    this.state = 'recording';
    return this.call('record');
  }

  play (id) {
    return this.call('play', { amor_id: id });
  }

  edit (id, shouldUpload = true, uploaded = true, context = null) {
    this.context = context;

    if (!this.isReady() || this.state === 'uploading') {
      logger.error('Can not start editing currently.');
      return;
    }

    if(!uploaded) {
      this.fileExists(id).then(result => {
        if (result.exists === true) {
          this.state = 'editing';
          return this.call('edit', { amor_id: id, upload: shouldUpload });
        } else {
          alertDialog({ content: VERSTEHE.vueI18n.t('media_library.take_not_present') });
        }
      });
    } else {
      this.state = 'editing';
      return this.call('edit', { amor_id: id, upload: shouldUpload });
    }
  }

  slideAudioRecorder (id, page, title, filetype, contenttype, origin = null) {
    if (!this.isReady() || this.state === 'uploading') {
      logger.error('Can not start slide audio recorder currently.');
      return;
    }
    
    if (origin) {
      this.origin = origin;
    }

    this.state = 'recording';
    return this.call('start_audio_recorder', { slide_id: id, page: page, title: title, file_type: filetype, content_type: contenttype });
  }

  openTakeManager () {
    return this.call('open_take_manager');
  }

  importVideo () {
    if (!this.isReady() || this.state === 'uploading') {
      logger.error('Can not import video currently.');
    }

    this.trigger('import');

    return this.call('import_video');
  }

  upload (id, callback) {
    if (!this.isReady() || this.state === 'uploading') {
      logger.error('Can not start upload currently.');
      return;
    }

    this.fileExists(id).then(response => {
      if (response.exists === true) {
        logger.debug(`Upload triggered for Amor#${id}`);
        this.onuploadcomplete = callback;
        this.call('upload', { amor_id: id });
      } else {
        this.trigger('upload-invalid');
      }
    });
  }

  deleteFile (id) {
    return new Promise((resolve, reject) => {
      this.call('delete', { amor_id: id }).then(response => {
        if (response.success) {
          resolve(response);
        } else {
          reject(response);
        }
      }).catch((error) => {
        reject(error);
      });
    });
  }

  fileExists (id) {
    return this.call('file_exists', { amor_id: id });
  }

  resetRecorder () {
    return this.call('reset_recorder');
  }

  loaded (message) {
    if (this.setupButton) {
      this.launchButton.style.display = 'none';
    }

    this.hideHelpPopup();
    this.loadedApp = true;
    this.trigger('loaded');

    if (typeof this.onready === 'function') {
      this.onready();
    }
  }

  recordSuccess (message) {
    this.state = 'loaded';

    this.getAmor(message.amor_id).then(result => {
      logger.debug('Record success', message);
      this.trigger('record-success', result);
    });
  }

  splitAmorSuccess (message) {
    this.getAmor(message.amor_id).then(result => {
      logger.debug('Split amor success', message);
      this.trigger('split-amor-success', result);
    });
  }

  slideAudioRecordSuccess (message) {
    const origin = this.origin;
    this.getAmor(message.amor_id).then(result => {
      result["origin"] = origin;
      logger.debug('Slide audio record success', message);
      this.trigger('slide-audio-record-success', result);
    });
  }

  importSuccess (message) {
    this.state = 'loaded';

    this.getAmor(message.amor_id).then(result => {
      logger.debug('Import success', message);
      this.trigger('import-success', result);
    });
  }

  importFail (message) {
    this.state = 'loaded';
    logger.error('Import failed', message);
    this.trigger('import-failed');
  }

  convertStatus (message) {
    onConvertProgress(message.amor_id, message.progress);
    this.trigger('convert-status', message.amor_id, message.progress);
  }

  convertSuccess (message) {
    this.getAmor(message.amor_id).then(result => {
      logger.debug('Convert success', message);
      onConvertSuccess(result);
      this.trigger('convert-success', result);
    });
  }

  refreshAmor (message) {
    this.getAmor(message.amor_id).then(result => {
      this.trigger('refresh-amor', result);
    });
  }

  videoEdited (message) {
    this.trigger('video-edited', message.amor_id);
  }

  exitScreenRecorder () {
    this.origin = null;
    this.state = 'loaded';
    this.trigger('exit');
  }

  exitEditing () {
    this.state = 'loaded';
  }

  uploadStatus (message) {
    onUploadProgress(message.amor_id, message.progress);
    this.trigger('upload-status', message.amor_id, message.progress);
  }

  uploadFinished (message) {
    this.state = 'loaded';
    this.trigger('upload-finished', message.amor_id);

    if (this.onuploadcomplete && typeof this.onuploadcomplete === 'function') {
      this.onuploadcomplete();
      this.onuploadcomplete = null;
    }
  }

  uploadFailed (message) {
    this.state = 'loaded';
    this.trigger('upload-failed', message.amor_id);
  }
}


function generateUID () {
  let S4 = () => { return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1); };
  return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4());
}
