import WidgetGrid from './widget_grid';
import SliderWidget from './widgets/slider_widget';

export let wrapper, targetGroups, roles, widgetGrid;

export function init() {
  wrapper = document.querySelector('.landing-page-widget-grid');

  initTargetGroupSelector();
  initWidgetItemDrag();
  bindListeners();

  if (wrapper) {
    widgetGrid = new WidgetGrid(wrapper);
    widgetGrid.initializedTrees = VERSTEHE.Taxonomy.trees;
  }

  // Initialize slider widget on gettting started page
  let gettingStartedWidget = document.querySelector('.getting-started-contributions > .landing-page-widget-slider');

  if (gettingStartedWidget) {
    let widget = new SliderWidget({});
    widget.wrapper = gettingStartedWidget.parentNode;
    widget.initialize();
  }
}

/**
 * Bind Listeners
 */
function bindListeners() {
  // Click on save button submits the form
  $(document).on('click', '#save_landing_page_btn', function () {
    $('#save_landing_page').submit();
  });

  $(document).on('click', '.landing-page-popover-head', function () {
    if (!$(this).parent().hasClass('widget-edit-active')) {
      $('body').toggleClass('show-popover');

      if ($('body').hasClass('show-popover')) {
        $(this).find('.landing-page-popover-toggle').text('expand_more');
      } else {
        $(this).find('.landing-page-popover-toggle').text('expand_less');
      }
    }
  });

  $(document).on('click', '.landing-page-widget-selector-item', function (e) {
    widgetGrid.add(this);
  });

  $(document).on('click', '.btn.submit-widget-grid', function (e) {
    e.preventDefault(e);
    widgetGrid.submit();
  });
}

/**
 * Make widget items in popover draggable
 */
function initWidgetItemDrag() {
  $('.landing-page-popover .landing-page-widget-selector-item').draggable({
    appendTo: '.landing-page-widget-grid.grid-stack',
    revert: 'invalid',
    helper: function () {
      let element = document.createElement('div');
      element.className = 'grid-stack-item';
      let content = document.createElement('div');
      content.className = 'grid-stack-item-content';
      element.appendChild(content);
      element.setAttribute('data-gs-width', this.getAttribute('data-default-width'));
      element.setAttribute('data-gs-height', this.getAttribute('data-default-height'));
      return element;
    },
    start: function (e, ui) {
      let grid = document.querySelector('.landing-page-widget-grid');
      grid.style.minHeight = '300px';
      let node = widgetGrid.gridstack.grid._prepareNode({
        width: parseInt(this.getAttribute('data-default-width'), 10),
        height: parseInt(this.getAttribute('data-default-height'), 10),
        _added: false,
        _temporary: true,
      });

      // Mock _triggerRemoveEvent function
      node._grid = { _triggerRemoveEvent: () => {} };
      $(this).data('_gridstack_node', node);
      $('body').removeClass('show-popover');
    },
    stop: function (e, ui) {
      let grid = document.querySelector('.landing-page-widget-grid');
      grid.style.minHeight = '0';
      $('body').addClass('show-popover');
    },
  });
}

/**
 * Initialize the target group selectize field
 */
function initTargetGroupSelector() {
  targetGroups = $('.landing-pages .target-group-select').selectize({
    plugins: ['clear_button'],
    render: {
      option: (item, escape) => {
        let metadataList = Object.keys(item.metadata).map((metadata, index) => {
          return (
            '<div class="meta">' +
            '<span class="meta-title">' +
            escape(metadata) +
            ':</span>' +
            '<ul class="meta-item">' +
            item.metadata[metadata]
              .map((value) => {
                return '<li>' + escape(value) + '</li>';
              })
              .join('') +
            '</ul>' +
            '</div>'
          );
        });

        return (
          '<div>' +
          '<span class="title">' +
          escape(item.text) +
          '</span>' +
          '<span class="description">' +
          escape(item.description) +
          '</span>' +
          metadataList.join('') +
          '</div>'
        );
      },
    },
  });
}
