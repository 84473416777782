import { Recorder } from './recorder';
import { confirmDialog } from '../common/dialog';
import UploaderService from '../uppy_uploader/uploader_service';
import { t } from './localization';
import { BbbImport } from './bbb_import/bbb_import';
import isBrowserSupportedByCapture from '../capture/utils/is_browser_supported';
import { ResourceType, ResourceSource } from '../common/resource_enum';

const STATES = ['initial', 'recording'];

export class VideoCreator {
  constructor () {
    this.openBtn = document.querySelector('.new-video-btn');
    this.openCaptureBtn = document.querySelector('.open-capture-btn');
    this.pageUnloading = false;
    this.openedFromMediaLibrary = false;
    this.recorder = new Recorder();

    if (this.openBtn) {
      this.openBtn.addEventListener('click', e => {
        e.preventDefault();

        if (!this.openBtn.classList.contains('disabled')) {
          this.loadAndOpen();
        }

        this.openBtn.blur();
      });

      if (window.location.hash === '#video-creator') {
        this.loadAndOpen();
      }
    }

    if (this.openCaptureBtn) {
      this.openCaptureBtn.addEventListener('click', (e) => {
        e.preventDefault();

        if (!this.openCaptureBtn.classList.contains('disabled')) {
          this.onOpenCaptureClick();
        }
      });
    }
  }

  load () {
    let url = this.openBtn.getAttribute('data-url');
    window.location.hash = '#video-creator';
    this.openBtn.classList.add('disabled');

    return new Promise((resolve, reject) => {
      $.get(url, response => {
        let tmp = document.createElement('div');
        tmp.innerHTML = response;

        if (this.wrapper) {
          document.body.removeChild(this.wrapper);
        }

        this.wrapper = tmp.firstChild;
        document.body.appendChild(this.wrapper);
        this.initiate();
        $.material.init();
        resolve();
      }).fail(reject);
    });
  }

  initiate () {
    this.setDomElements();

    if (!isBrowserSupportedByCapture()) {
      this.webrtcBtn.querySelector('.browser-not-supported').classList.remove('hidden');
    }

    this.dropZone = document.querySelector('.video-creator-import .drop-zone');
    this.uploader = UploaderService.videoUploaderInstance;
    this.uploader.useDropDown(this.dropZone);
    this.uploader.uppy.on('file-added', (file) => {
      // Adds an external file to the uploader.
      if (file.type.includes('audio')) {
        this.uploader.uppy.setFileMeta(file.id, {
          resource_type: ResourceType.Audio,
          resource_source: ResourceSource.External,
        });
      } else {
        this.uploader.uppy.setFileMeta(file.id, {
          resource_type: ResourceType.Unknown,
          resource_source: ResourceSource.External,
        });
      }
      this.close();
      VERSTEHE.MediaLibrary.library.openLibrary();
    });

    this.kendoBbbModal = $('#bbb_modal').kendoWindow({
      modal: true,
      visible: false,
      draggable: false,
      resizable: false,
      title: t('bbb_title')
    }).data("kendoWindow");

    this.BbbImport = new BbbImport('#v-bbb-wrapper',(takeId) => this.onBbbImportCallback(takeId));

    this.bindListeners();
  }

  onBbbImportCallback(takeId) {
    this.kendoBbbModal.close();
    this.close();
    VERSTEHE.MediaLibrary.library.openLibrary(undefined, 'privateTakes', undefined, { type: 'take', id: takeId });

  }

  setDomElements() {
    this.exitBtn = this.wrapper.querySelector('.video-creator-exit');
    this.launchRecorderBtn = document.getElementById('video-creator-record');
    this.selectFilesElement = document.querySelector('.video-creator-import-select');
    this.importBbbBtn = this.wrapper.querySelector('#video-creator-import-bbb');
    this.webrtcBtn = document.querySelector('#video-creator-capture');
  }

  animateBrand () {
    let brandImage = document.querySelector('.navbar-brand > img');

    if (this.brandAnimation && this.wrapper.contains(this.brandAnimation)) {
      this.wrapper.removeChild(this.brandAnimation);
    }

    this.brandAnimation = document.createElement('div');
    this.brandAnimation.className = 'video-creator-brand';

    brandImage = brandImage.cloneNode();

    if (brandImage.dataset.alt) {
      brandImage.src = brandImage.dataset.alt;
    }

    this.brandAnimation.appendChild(brandImage.cloneNode());
    this.wrapper.appendChild(this.brandAnimation);
  }

  loadAndOpen (fromLibrary = false) {
    this.openedFromMediaLibrary = fromLibrary;

    if (!this.wrapper) {
      this.load().then(() => {
        this.open();
      });
    } else {
      this.open();
    }
  }

  open () {
    this.animateBrand();
    document.body.classList.add('video-creator-visible');
    this.previousLocationHash = window.location.hash;
    window.location.hash = '#video-creator';

    this.keyListener = document.addEventListener('keydown', e => {
      if (e.keyCode === 27) {
        // ESC
        this.close();
      }
    });
  }

  close () {
    document.body.classList.remove('video-creator-visible');
    document.removeEventListener('keydown', this.keyListener);

    if(VERSTEHE.ContributionWorkflow.workflow && VERSTEHE.ContributionWorkflow.workflow.workflowApp) {
      const active = VERSTEHE.ContributionWorkflow.workflow.getActiveSteps();
      window.location.hash = active.subStep !== null ? active.subStep : active.step;
    } else {
      window.location.hash = this.previousLocationHash !== '#video-creator' ? this.previousLocationHash : '';
    }
    this.openBtn.classList.remove('disabled');
  }

  setState (state) {
    this._state = state;

    STATES.forEach(state => {
      this.wrapper.classList.remove(`state-${state}`);
    });

    this.wrapper.classList.add(`state-${state}`);

    if (state === 'initial') {
      this.launchRecorderBtn.classList.remove('disabled');
    }
  }

  getState () {
    return this._state;
  }

  setVideoType (videoType) {
    this.videoType = videoType;
  }

  getVideoType () {
    return this.videoType;
  }

  setAmor (amorId) {
    if (amorId) {
      this.recorder.loadAmor(amorId).then(recordedVideo => {
        if (recordedVideo.amor.uploaded) {
          this.displayPreviewVideo(recordedVideo.amor.source);
          this.resumableState.amor_id = amorId;
        }
      });
    }
  }

  getAmor () {
    return this.recorder.amorId;
  }

  bindListeners () {
    this.exitBtn.addEventListener('click', (e) => {
      this.close();
      this.exitBtn.blur();
    });

    this.launchRecorderBtn.addEventListener('click', (e) => {
      e.preventDefault();
      this.recorder.setLaunchButton(this.launchRecorderBtn);
      this.recorder.start();
      this.launchRecorderBtn.blur();
    });

    this.webrtcBtn.addEventListener('click', () => this.onOpenCaptureClick());

    this.recorder.on('loaded', () => {
      window.addEventListener('beforeunload', () => {
        this.pageUnloading = true;
      });
    });

    this.recorder.on('state-change', state => {
      if (this.pageUnloading) {
        // Ignore, if the page is unloading
        return;
      }

      switch (state) {
        case 'recording':
          this.setState('recording');
          break;
        default:
          this.setState('initial');
          break;
      }
    });

    this.recorder.on('record-success', (takeId) => {
      VERSTEHE.MediaLibrary.library.openLibrary(undefined, 'privateTakes', undefined, { type: 'take', id: takeId });
      this.close();
    });

    this.importBbbBtn && this.importBbbBtn.addEventListener('click', () => this.showBbbImportModal());
  }

  onOpenCaptureClick() {
    this.recorder.recorder.ensurePrivacyDisclaimer().then(() => {
      this.openCapture();
    });
  }

  openCapture() {
    const captureUrl = document.querySelector('.navbar').getAttribute('data-capture-url');
    const openInNewTab = () => window.open(captureUrl, '_blank');

    if (isBrowserSupportedByCapture()) {
      openInNewTab();
    } else {
      confirmDialog({
        title: t('unsupported_browser_title'),
        content: t('unsupported_browser_body'),
      }).then(() => {
        openInNewTab();
      }).catch(() => {
      });
    }
  }

  showBbbImportModal() {
    this.kendoBbbModal.open();
    this.kendoBbbModal.center();
  }
}
