import common from './common';
import course from './course';
import course_plan from './course_plan';
import validations from './validations';
import media_library from './media_library';
import screenplay from './screenplay';
import film_production from './film_production';
import film_questions from './film_questions';
import film_transcript from './film_transcript';
import quiz from './quiz';
import change_history_events from './change_history_events';
import integrations from './integrations';
import tutorials from './tutorials';
import getting_started from './getting_started';
import education_rollout from './education_rollout';
import globals from './globals';
import webrtc_recorder from './webrtc_recorder';
import playlist from './playlist';
import search_component from './search_component';
import user_import from './user_import';
import user_onboarding from './user_onboarding';
import media_assembling_templates from './media_assembling_templates';
import scorm from './scorm';
import web_conference from './web_conference';
import film_subtitles from './film_subtitles';
import api_tokens from './api_tokens';
import twofactor from './twofactor';
import new_video_dialog from './new_video_dialog';
import reassign_modal from './reassign_modal';

export default {
  en: {
    common: common.en,
    course: course.en,
    course_plan: course_plan.en,
    validations: validations.en,
    media_library: media_library.en,
    screenplay: screenplay.en,
    film_production: film_production.en,
    film_questions: film_questions.en,
    quiz: quiz.en,
    change_history_events: change_history_events.en,
    integrations: integrations.en,
    tutorials: tutorials.en,
    getting_started: getting_started.en,
    education_rollout: education_rollout.en,
    globals: globals.en,
    webrtc_recorder: webrtc_recorder.en,
    playlist: playlist.en,
    search_component: search_component.en,
    user_import: user_import.en,
    film_transcript: film_transcript.en,
    film_subtitles: film_subtitles.en,
    user_onboarding: user_onboarding.en,
    media_assembling_templates: media_assembling_templates.en,
    scorm: scorm.en,
    web_conference: web_conference.en,
    api_tokens: api_tokens.en,
    twofactor: twofactor.en,
    new_video_dialog: new_video_dialog.en,
    reassign_modal: reassign_modal.en
  },
  de: {
    common: common.de,
    course: course.de,
    course_plan: course_plan.de,
    validations: validations.de,
    media_library: media_library.de,
    screenplay: screenplay.de,
    film_production: film_production.de,
    film_questions: film_questions.de,
    quiz: quiz.de,
    change_history_events: change_history_events.de,
    integrations: integrations.de,
    tutorials: tutorials.de,
    getting_started: getting_started.de,
    education_rollout: education_rollout.de,
    globals: globals.de,
    webrtc_recorder: webrtc_recorder.de,
    playlist: playlist.de,
    search_component: search_component.de,
    user_import: user_import.de,
    film_transcript: film_transcript.de,
    film_subtitles: film_subtitles.de,
    user_onboarding: user_onboarding.de,
    media_assembling_templates: media_assembling_templates.de,
    scorm: scorm.de,
    web_conference: web_conference.de,
    api_tokens: api_tokens.de,
    twofactor: twofactor.de,
    new_video_dialog: new_video_dialog.de,
    reassign_modal: reassign_modal.de
  },
  es: {
    common: common.es,
    course: course.es,
    course_plan: course_plan.es,
    validations: validations.es,
    media_library: media_library.es,
    screenplay: screenplay.es,
    film_production: film_production.es,
    film_questions: film_questions.es,
    quiz: quiz.es,
    change_history_events: change_history_events.es,
    integrations: integrations.es,
    tutorials: tutorials.es,
    getting_started: getting_started.es,
    education_rollout: education_rollout.es,
    globals: globals.es,
    webrtc_recorder: webrtc_recorder.es,
    playlist: playlist.es,
    search_component: search_component.es,
    user_import: user_import.es,
    film_transcript: film_transcript.es,
    film_subtitles: film_subtitles.de,
    user_onboarding: user_onboarding.es,
    media_assembling_templates: media_assembling_templates.es,
    scorm: scorm.es,
    web_conference: web_conference.es,
    api_tokens: api_tokens.es,
    twofactor: twofactor.es,
    new_video_dialog: new_video_dialog.es,
    reassign_modal: reassign_modal.es
  }
};
